import { KEAP_ATTRIBUTES, KEAP_TAGS } from '@/hosting/hosting.constants';

import lodashIsEmpty from 'lodash/isEmpty';

/**
 * Attempts to locate the version of keap-hosting that should be applied to this element.
 *
 * - (deprecated) If the attribute data-keap-form-version exists on this element, it will be used.
 * - Otherwise, it will look for a <keap-hosting> tag that has a version attribute
 *
 * @param {HTMLElement} element
 * @return {string} version number
 */
export function findHostingVersion(element) {
    const hostingVersionFromAttribute = element.getAttribute(KEAP_ATTRIBUTES.formVersion);

    return hostingVersionFromAttribute ??
        element
            .closest(`${KEAP_TAGS.keapHosting}[${KEAP_ATTRIBUTES.keapHostingVersion}]`)
            ?.getAttribute(KEAP_ATTRIBUTES.keapHostingVersion);
}

export function loadExternalScript(id, url, { onload, onerror }) {
    if (!document.getElementById(id)) {
        const script = document.createElement('script');

        script.src = url;
        script.id = id;

        script.onload = onload;
        script.onerror = onerror;

        document.head.appendChild(script);
    }
}

/**
 *
 * @param {HTMLElement} element
 * @return {boolean}
 */
export function isPreviewMode(element) {
    return element.closest(KEAP_TAGS.keapHosting)?.getAttribute(KEAP_ATTRIBUTES.isPreview) === 'true';
}

export function isEmptyValue(from) {
    if (from === null) return true;

    switch (typeof from) {
    case 'undefined':
        return true;
    case 'boolean':
        return false;
    case 'number':
        return false;
    case 'bigint':
        return false;
    default:
        return lodashIsEmpty(from);
    }
}

export const mapEntries = (input, mapper) => Object.entries(input ?? {})
    .reduce((prev, [k, v]) => {
        const [kk, vv] = mapper(k, v) ?? [];

        if (kk) prev[kk] = vv;

        return prev;
    }, {});

export const filterEntries = (input, predicate) => Object.entries(input ?? {})
    .reduce((prev, [k, v]) => {
        if (predicate(k, v)) {
            prev[k] = v;
        }

        return prev;
    }, {});

export const pruneEmpty = (input) => filterEntries(input, (key, value) => !isEmptyValue(value));

/**
 * Finds all parents that match the defined selector, ordered from closest to furthest.
 *
 * @param {Element} element
 * @param {string} selector
 * @return {[Element]}
 */
export function parentsOf(element, selector) {
    const parents = [];
    let parent = element.parentElement?.closest(selector);

    while (parent) {
        parents.unshift(parent);
        parent = parent.parentElement?.closest(selector);
    }

    return parents;
}

/**
 * Builds an event scope based on an element within the document.  This looks up the tree for keap-scope elements
 * and collects all the attributes together
 *
 * @param {Element} element
 * @return {object}
 */
export function findParentScopes(element) {
    return parentsOf(element, KEAP_TAGS.keapScope)
        .reduce((prev, p) => {
            p.getAttributeNames().forEach((name) => {
                prev[name] = p.getAttribute(name);
            });

            return prev;
        }, {});
}

/**
 * Looks in the tree for a success-redirect to use.  This is typically attached to the keap-form keap-hosting elements.
 *
 * This function should ignore any blank values for success-redirect
 *
 * @param {Element} element The element used to do the lookups
 * @return {string|null}
 */
export function findSuccessPage(element) {
    const redirectElement = element?.closest('[success-redirect]:not([success-redirect=""])');

    return redirectElement?.getAttribute(KEAP_ATTRIBUTES.formSuccessRedirect);
}


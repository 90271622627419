export const KEAP_EVENT_NAMES = {
    pageViewed: 'marketing-page-viewed',
    formViewed: 'marketing-form-viewed',
    formStarted: 'marketing-form-started',
    formError: 'marketing-form-submission-failed',
    formSubmitted: 'marketing-form-submitted',
    sourceChanged: 'marketing-source-changed',
};

export const KEAP_EVENT_PROPERTIES = {
    formSlug: 'form-slug',
    errorCode: 'error-code',
};
